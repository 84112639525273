import React, { useContext, useEffect, useState } from "react";
import Header from "./Header";
import Counter from "./Counter";
import GameGrid from "./GameGrid";
import BtmGrid from "./BtmGrid";
import PostGameModal from "./PostGameModal";

import { fixedCells } from "../Support/Objects";
import puzzleGen from "../Functions/puzzleGen";

export const GameContext = React.createContext(null);

export default function Game(props) {
  ////    ////    inits       ////    ////
  const diffMenu = [
    "easy",
    "not_so_easy",
    "slightly_stressful",
    "kinda_hard",
    "pretty_damn_tricky",
    "break_my_brain",
  ];

  ////    ////    states      ////    ////
  const [gameVals, setGameVals] = useState({});
  const [btmVals, setBtmVals] = useState({});
  const [solutions, setSolutions] = useState({});
  const [pressed, setPressed] = useState([]);
  const [pressable, setPressable] = useState(false);
  const [cellsToFix, setCellsToFix] = useState([]);
  const [solved, setSolved] = useState(false);
  const [hintMode, setHintMode] = useState("default");
  const [sums, setSums] = useState({
    c1_6: null,
    c6_1: null,
    c2_6: null,
    c6_2: null,
    c4_0: null,
    c0_4: null,
    c5_0: null,
    c0_5: null,
    c0_3: null,
    c3_6: null,
    c6_3: null,
    c3_0: null,
  });
  const [pulsing, setPulsing] = useState({
    c1_6: false,
    c6_1: false,
    c2_6: false,
    c6_2: false,
    c4_0: false,
    c0_4: false,
    c5_0: false,
    c0_5: false,
    c0_3: false,
    c3_6: false,
    c6_3: false,
    c3_0: false,
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [phase, setPhase] = useState("pregame");
  const [metaDiff, setMetaDiff] = useState(3);
  const [colourScheme, setColourScheme] = useState({
    red: "red",
    orange: "orange",
    yellow: "yellow",
    green: "limegreen",
    blue: "dodgerblue",
    violet: "violet",
    background: "rgba(0,0,0,0.3)",
  });
  const [checkingColour, setCheckingColour] = useState("");
  const [colourVal, setColourVal] = useState("");
  const [colourRef, setColourRef] = useState("");

  ////    ////    functions       ////    ////
  const updateColourScheme = (arg1, arg2) => {
    setCheckingColour(arg1);
    setColourRef(arg2);
  };

  const initialise = async (diff) => {
    const initProm = new Promise((resolve, reject) => {
      try {
        const puzzle = puzzleGen(diffMenu[diff]);
        resolve(puzzle);
        console.log(puzzle);
      } catch (err) {
        console.log(err);
      }
    });

    initProm.then((puzzle) => {
      //  If cell is fixed, set value == solution; otherwise, set value == null.
      setGameVals({
        c1_1: puzzle[2].red,
        c1_2: puzzle[3]["c1_2"] === true ? puzzle[0].red.east[0] : null,
        c1_3: puzzle[3]["c1_3"] === true ? puzzle[0].red.east[1] : null,
        c1_4: puzzle[3]["c1_4"] === true ? puzzle[0].red.east[2] : null,
        c1_5: puzzle[3]["c1_5"] === true ? puzzle[0].red.east[3] : null,
        c2_1: puzzle[3]["c2_1"] === true ? puzzle[0].red.south[0] : null,
        c2_2: puzzle[2].orange,
        c2_3: puzzle[3]["c2_3"] === true ? puzzle[0].orange.east[0] : null,
        c2_4: puzzle[3]["c2_4"] === true ? puzzle[0].orange.east[1] : null,
        c2_5: puzzle[3]["c2_5"] === true ? puzzle[0].orange.east[2] : null,
        c3_1: puzzle[3]["c3_1"] === true ? puzzle[0].red.south[1] : null,
        c3_2: puzzle[3]["c3_2"] === true ? puzzle[0].orange.south[0] : null,
        c3_3: puzzle[2].yellow,
        c3_4: puzzle[3]["c3_4"] === true ? puzzle[0].green.north[0] : null,
        c3_5: puzzle[3]["c3_5"] === true ? puzzle[0].blue.north[1] : null,
        c4_1: puzzle[3]["c4_1"] === true ? puzzle[0].red.south[2] : null,
        c4_2: puzzle[3]["c4_2"] === true ? puzzle[0].orange.south[1] : null,
        c4_3: puzzle[3]["c4_3"] === true ? puzzle[0].yellow.south[0] : null,
        c4_4: puzzle[2].green,
        c4_5: puzzle[3]["c4_5"] === true ? puzzle[0].blue.north[0] : null,
        c5_1: puzzle[3]["c5_1"] === true ? puzzle[0].red.south[3] : null,
        c5_2: puzzle[3]["c5_2"] === true ? puzzle[0].orange.south[2] : null,
        c5_3: puzzle[3]["c5_3"] === true ? puzzle[0].yellow.south[1] : null,
        c5_4: puzzle[3]["c5_4"] === true ? puzzle[0].blue.west[0] : null,
        c5_5: puzzle[2].blue,
      });

      //  Populate BtmCells from longArray.
      setBtmVals({
        b0: puzzle[1][0],
        b1: puzzle[1][1],
        b2: puzzle[1][2],
        b3: puzzle[1][3],
        b4: puzzle[1][4],
        b5: puzzle[1][5],
        b6: puzzle[1][6],
        b7: puzzle[1][7],
        b8: puzzle[1][8],
        b9: puzzle[1][9],
        b10: puzzle[1][10],
        b11: puzzle[1][11],
        b12: puzzle[1][12],
        b13: puzzle[1][13],
        b14: puzzle[1][14],
        b15: puzzle[1][15],
        b16: puzzle[1][16],
        b17: puzzle[1][17],
        b18: puzzle[1][18],
        b19: puzzle[1][19],
      });

      //  Store solutions data.
      setSolutions({
        c0_3: puzzle[2].yellow,
        c0_4: puzzle[2].green,
        c0_5: puzzle[2].blue,
        c1_1: puzzle[2].red,
        c1_2: puzzle[0].red.east[0],
        c1_3: puzzle[0].red.east[1],
        c1_4: puzzle[0].red.east[2],
        c1_5: puzzle[0].red.east[3],
        c1_6: puzzle[2].red,
        c2_1: puzzle[0].red.south[0],
        c2_2: puzzle[2].orange,
        c2_3: puzzle[0].orange.east[0],
        c2_4: puzzle[0].orange.east[1],
        c2_5: puzzle[0].orange.east[2],
        c2_6: puzzle[2].orange,
        c3_0: puzzle[2].yellow,
        c3_1: puzzle[0].yellow.west[1],
        c3_2: puzzle[0].yellow.west[0],
        c3_3: puzzle[2].yellow,
        c3_4: puzzle[0].yellow.east[0],
        c3_5: puzzle[0].yellow.east[1],
        c3_6: puzzle[2].yellow,
        c4_0: puzzle[2].green,
        c4_1: puzzle[0].green.west[2],
        c4_2: puzzle[0].green.west[1],
        c4_3: puzzle[0].green.west[0],
        c4_4: puzzle[2].green,
        c4_5: puzzle[0].blue.north[0],
        c5_0: puzzle[2].blue,
        c5_1: puzzle[0].blue.west[3],
        c5_2: puzzle[0].blue.west[2],
        c5_3: puzzle[0].blue.west[1],
        c5_4: puzzle[0].blue.west[0],
        c5_5: puzzle[2].blue,
        c6_1: puzzle[2].red,
        c6_2: puzzle[2].orange,
        c6_3: puzzle[2].yellow,
      });

      setCellsToFix([]);
      //  Set pressable == true after slight delay to prevent cellsToFix animation running on initial render.
      setTimeout(() => {
        setPressable(true);
      }, 250);
    });
  };

  const btmPress = (id, val) => {
    try {
      setPressed([id, val]);
    } catch (err) {
      console.log(err);
    }
  };

  const liftNumber = (id) => {
    try {
      if (pressed[0] !== null) {
        //  If a btmCell has been pressed ...
        //  Set the value of the pressed gameCell to the value of the pressed btmCell.
        setGameVals({ ...gameVals, [id]: pressed[1] });
        //  Set the value of the pressed btmCell to null.
        setBtmVals({ ...btmVals, [pressed[0]]: null });
        //  Reset pressed array to [null, null].
        setPressed([null, null]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const dropNumber = (id, val) => {
    try {
      //  Set value of pressed gameCell to null.
      setGameVals({ ...gameVals, [id]: null });
      //  Set value of first vacant btmCell == to the dropped number.
      for (var i = 0; i < Object.keys(btmVals).length; i++) {
        if (btmVals["b" + i] === null) {
          setBtmVals({ ...btmVals, ["b" + i]: val });
          break;
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const updateSums = () => {
    // Update sum cells whenever a cell is changed.
    setSums({
      c1_6:
        gameVals["c1_2"] +
        gameVals["c1_3"] +
        gameVals["c1_4"] +
        gameVals["c1_5"],
      c6_1:
        gameVals["c2_1"] +
        gameVals["c3_1"] +
        gameVals["c4_1"] +
        gameVals["c5_1"],
      c2_6: gameVals["c2_3"] + gameVals["c2_4"] + gameVals["c2_5"],
      c6_2: gameVals["c3_2"] + gameVals["c4_2"] + gameVals["c5_2"],
      c4_0: gameVals["c4_1"] + gameVals["c4_2"] + gameVals["c4_3"],
      c0_4: gameVals["c1_4"] + gameVals["c2_4"] + gameVals["c3_4"],
      c5_0:
        gameVals["c5_1"] +
        gameVals["c5_2"] +
        gameVals["c5_3"] +
        gameVals["c5_4"],
      c0_5:
        gameVals["c1_5"] +
        gameVals["c2_5"] +
        gameVals["c3_5"] +
        gameVals["c4_5"],
      c0_3: gameVals["c1_3"] + gameVals["c2_3"],
      c3_6: gameVals["c3_4"] + gameVals["c3_5"],
      c6_3: gameVals["c4_3"] + gameVals["c5_3"],
      c3_0: gameVals["c3_1"] + gameVals["c3_2"],
    });
  };

  const solveHalf = (arg) => {
    try {
      if (hintMode !== "default") {
        setHintMode("default");
      } else {
        if (arg === "solve-left") {
          setHintMode("solve-left");
        } else if (arg === "solve-right") {
          setHintMode("solve-right");
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleStartGame = (diff) => {
    setMetaDiff(diff);
    initialise(diff);
    setPhase("animating-intro");
    setTimeout(() => {
      setPhase("game-active");
    }, 3000);
  };

  ////    ////    useEffects      ////    ////
  useEffect(() => {
    //initialise();
  }, []);

  useEffect(() => {
    try {
      updateSums();
    } catch (err) {
      console.log(err);
    }
  }, [gameVals]);

  useEffect(() => {
    try {
      if (sums.c1_6 === sums.c6_1 && sums.c1_6 !== 0 && sums.c1_6 !== null) {
        if (sums.c2_6 === sums.c6_2 && sums.c2_6 !== 0 && sums.c2_6 !== null) {
          if (
            sums.c4_0 === sums.c0_4 &&
            sums.c4_0 !== 0 &&
            sums.c4_0 !== null
          ) {
            if (
              sums.c5_0 === sums.c0_5 &&
              sums.c5_0 !== 0 &&
              sums.c5_0 !== null
            ) {
              if (hintMode !== "default") {
                solveHalf("default");
                setTimeout(() => {
                  setPressable(false);
                  setSolved(true);
                }, 250);
              } else {
                setPressable(false);
                setSolved(true);
              }
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [sums]);

  useEffect(() => {
    try {
      var localArray = [
        "c0_3",
        "c0_4",
        "c0_5",
        "c1_6",
        "c2_6",
        "c3_6",
        "c6_3",
        "c6_2",
        "c6_1",
        "c5_0",
        "c4_0",
        "c3_0",
      ];
      if (solved === true) {
        for (let i = 0; i < localArray.length; i++) {
          setTimeout(() => {
            setPulsing({ ...pulsing, [localArray[i]]: true });
          }, i * 250);
          setTimeout(() => {
            setModalOpen(true);
            setTimeout(() => {
              setPhase("pregame");
              setSolved(false);
              setPulsing({
                c1_6: false,
                c6_1: false,
                c2_6: false,
                c6_2: false,
                c4_0: false,
                c0_4: false,
                c5_0: false,
                c0_5: false,
                c0_3: false,
                c3_6: false,
                c6_3: false,
                c3_0: false,
              });
            }, 1000);
          }, 4000);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [solved]);

  useEffect(() => {
    try {
      if (phase === "game-active") {
        updateSums();
      }
    } catch (err) {
      console.log(err);
    }
  }, [phase]);

  ////    ////    styles      ////    ////
  const styles = {
    parentContainer: {
      position: "relative",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      width: props.width,
      border: "1px solid black",
      backgroundImage: `url("./canva1.png")`,
    },
  };
  ////    ////    component       ////    ////
  return (
    <GameContext.Provider
      value={{
        width: props.width,
        gameVals,
        btmVals,
        sums,
        solutions,
        pressed,
        pressable,
        cellsToFix,
        solved,
        hintMode,
        pulsing,
        phase,
        colourScheme,
        btmPress,
        liftNumber,
        dropNumber,
        solveHalf,
        handleStartGame,
        updateColourScheme,
      }}
    >
      <div style={styles.parentContainer}>
        <GameGrid />
        {1 === 2 ? (
          <BtmGrid diff={metaDiff} />
        ) : (
          <>
            <div>
              <input
                onChange={(e) => {
                  setColourVal(e.target.value);
                }}
              />
              <button
                onClick={() => {
                  setColourScheme({
                    ...colourScheme,
                    [checkingColour]: colourVal,
                  });
                }}
              >
                Update {colourRef}
              </button>
            </div>
            <button
              onClick={() => {
                setColourScheme({
                  ...colourScheme,
                  background: "rgba(0,0,0,0.3)",
                });
              }}
            >
              Reset background
            </button>
          </>
        )}

        {modalOpen === true ? (
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "90%",
              height: "90%",
              backgroundColor: "lightgreen",
              border: "1px solid black",
              padding: "10px",
            }}
          >
            <PostGameModal closeModal={closeModal} />
          </div>
        ) : null}
      </div>
      <div style={{ display: "flex", flexDirection: "column", padding: 5 }}>
        <p>Red: {colourScheme.red}</p>
        <p>Orange: {colourScheme.orange}</p>
        <p>Yellow: {colourScheme.yellow}</p>
        <p>Green: {colourScheme.green}</p>
        <p>Blue: {colourScheme.blue}</p>
        <p>Violet: {colourScheme.violet}</p>
      </div>
    </GameContext.Provider>
  );
}
