import React, { useEffect, useState } from "react";
import "../App.css";
import { useNavigate, useLocation } from "react-router-dom";

export default function NavItem(props) {
  const navigate = useNavigate();
  const location = useLocation();
  const [style, setStyle] = useState("navbar-text");

  useEffect(() => {
    if (props.path == location.pathname) {
      setStyle("navbar-text-selected");
    } else {
      setStyle("navbar-text");
    }
  }, [location]);

  return (
    <li
      className={style}
      onClick={() => {
        navigate(`${props.path}`);
      }}
    >
      {props.text}
    </li>
  );
}
