import React, { useContext, useState, useEffect } from "react";
import { boardSegments } from "../Support/Objects";
import { GameContext } from "./Game";
import "animate.css";

export default function GameCell(props) {
  ////    ////    inits       ////    ////
  const consumeCtxt = useContext(GameContext);

  ////    ////    states      ////    ////
  const [val, setVal] = useState(1);
  const [style, setStyle] = useState(null);
  const [borderRadius, setBorderRadius] = useState(4);
  const [opacity, setOpacity] = useState(1);

  ////    ////    functions       ////    ////
  const clickHandler = (arg) => {
    if (arg === "lift-number") {
      consumeCtxt.liftNumber(props.id);
    } else if (arg === "drop-number") {
      consumeCtxt.dropNumber(props.id, val);
    } else if (arg === "fix") {
      //
    } else if (arg === "unfix") {
      //
    }
  };

  ////    ////    useEffects      ////    ////
  useEffect(() => {
    try {
      setStyle(props.style);
    } catch (err) {
      console.log(err);
    }
  }, [props.style]);

  useEffect(() => {
    try {
      setVal(consumeCtxt.gameVals[props.id]);
    } catch (err) {
      console.log(err);
    }
  }, [consumeCtxt.gameVals]);

  useEffect(() => {
    try {
      if (style === "sum") {
        setVal(consumeCtxt.sums[props.id]);
        /*if (consumeCtxt.sums[props.id] == consumeCtxt.solutions[props.id]) {
          setBorderRadius(90);
        } else {
          setBorderRadius(4);
        }*/
      }
    } catch (err) {
      console.log(err);
    }
  }, [consumeCtxt.sums]);

  useEffect(() => {
    try {
      if (consumeCtxt.hintMode === "solve-right") {
        //  The imported object boardSegments divides the board into different left-inner, left-outer, right-inner and right-outer. The loops below darken the cells that belong to the left side of the board.
        for (var i in boardSegments.left.inner) {
          if (boardSegments.left.inner[i] === props.id) {
            setOpacity(0.25);
          }
        }
        for (var j in boardSegments.left.outer) {
          if (boardSegments.left.outer[j] === props.id) {
            setOpacity(0.25);
          }
        }
      } else if (consumeCtxt.hintMode === "solve-left") {
        for (var i in boardSegments.right.inner) {
          if (boardSegments.right.inner[i] === props.id) {
            setOpacity(0.25);
          }
        }
        for (var j in boardSegments.right.outer) {
          if (boardSegments.right.outer[j] === props.id) {
            setOpacity(0.25);
          }
        }
      } else {
        //  Under all other circumstances, opacity should be reset to 1.
        setOpacity(1);
      }
    } catch (err) {
      console.log(err);
    }
  }, [consumeCtxt.hintMode]);

  useEffect(() => {
    try {
      for (var i in consumeCtxt.cellsToFix) {
        if (consumeCtxt.cellsToFix[i] === props.id) {
          setStyle("fixed");
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [consumeCtxt.cellsToFix]);

  /*useEffect(() => {
    //
  }, [consumeCtxt.userFixed]);*/

  useEffect(() => {
    //
  }, [consumeCtxt.solved]);

  useEffect(() => {
    setTimeout(() => {
      if (style === "sum") {
        setVal(consumeCtxt.sums[props.id]);
      }
    }, 1000);
  }, []);
  ////    ////    styles      ////    ////
  const styles = {
    game: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "11%",
      border: "1px solid black",
      backgroundColor: "white",
      cursor: "pointer",
      opacity: opacity,
      borderRadius: borderRadius,
    },
    sum: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "11%",
      border: "1px solid black",
      backgroundColor: consumeCtxt.colourScheme[props.color],
      opacity: opacity,
      borderRadius: borderRadius,
    },
    inner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "11%",
      border: "1px solid black",
      backgroundColor: consumeCtxt.colourScheme[props.color],
      opacity: opacity,
      borderRadius: borderRadius,
    },
    nothing: {
      width: "11%",
      opacity: 0,
    },
    fixed: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "11%",
      border: "1px solid black",
      backgroundColor: "lightgrey",
      opacity: opacity,
      borderRadius: borderRadius,
    },
    userFixed: {},
  };
  ////    ////    component       ////    ////
  return (
    <div
      style={styles[style]}
      className={
        consumeCtxt.pulsing[props.id] === true
          ? "animate__animated animate__heartBeat"
          : null
      }
      onClick={(e) => {
        e.stopPropagation();
        if (style === "sum" || style === "inner") {
          consumeCtxt.updateColourScheme(props.color, props.letter);
        } else if (style === "nothing") {
          consumeCtxt.updateColourScheme("background", "background");
        }
      }}
    >
      <p>{style === "inner" || style === "sum" ? props.letter : null}</p>
    </div>
  );
}
