import React from "react";
import Game from "../Components/Summie/Components/Game";

export default function Game2() {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Game width={350} />
    </div>
  );
}
