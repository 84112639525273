import React from "react";

export default function PostGameModal({ closeModal }) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <button
        onClick={() => {
          closeModal();
        }}
      >
        PLAY AGAIN!
      </button>
    </div>
  );
}
