import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export default function Privacy() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <h1>Privacy</h1>
      <a href={process.env.PUBLIC_URL + "/summie_privacy_policy.pdf"} download>
        Download privacy policy
      </a>
    </motion.div>
  );
}
