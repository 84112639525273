import React, { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
//import Game from "./Game";
import Home from "./Home";
import Game2 from "./Game2";
import Privacy from "./Privacy";
import About from "./About";

import { AnimatePresence } from "framer-motion";

export default function AnimatedRoutes() {
  const location = useLocation();
  useEffect(() => {
    console.log(location);
  }, []);
  return (
    <AnimatePresence>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/test" element={<Game2 />} />
        </Routes>
      </div>
    </AnimatePresence>
  );
}
