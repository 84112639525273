import React from "react";
import "../App.css";
import NavItem from "./NavItem";

export default function NavBar() {
  return (
    <div className="navbar">
      <ul
        className="navbar-list"
        style={{
          listStyle: "none",
        }}
      >
        <NavItem path="/" text="Play Summie!" />
        <NavItem path="/about" text="About" />
        <NavItem path="/privacy" text="Privacy" />
      </ul>
    </div>
  );
}
