const gridInfo = {
  r0: [
    ["c0_0", "nothing", null, null, null, null],
    ["c0_1", "nothing", null, null, null, null],
    ["c0_2", "nothing", null, null, null, null],
    ["c0_3", "sum", "yellow", "yellow", "C", null],
    ["c0_4", "sum", "green", "green", "D", null],
    ["c0_5", "sum", "blue", "blue", "E", null],
    ["c0_6", "sum", "violet", "violet", "F", null],
    ["c0_7", "nothing", null, null, null, null],
  ],
  r1: [
    ["c1_0", "nothing", null, null, null, null],
    ["c1_1", "inner", "red", "red", "A", null],
    ["c1_2", "game", "red", "red", "c1_1", "c1_1"],
    ["c1_3", "game", "red", "red", "c1_1", "c1_1"],
    ["c1_4", "game", "red", "green", "c1_1", "c4_4"],
    ["c1_5", "game", "red", "blue", "c1_1", "c5_5"],
    ["c1_6", "game", "red", "violet", "c1_1", "c6_6"],
    ["c1_7", "sum", "red", "red", "A", null],
  ],
  r2: [
    ["c2_0", "nothing", null, null, null, null],
    ["c2_1", "game", "red", "red", "c1_1", "c1_1"],
    ["c2_2", "inner", "orange", "orange", "B", null],
    ["c2_3", "game", "orange", "orange", "c2_2", "c2_2"],
    ["c2_4", "game", "orange", "green", "c2_2", "c4_4"],
    ["c2_5", "game", "orange", "blue", "c2_2", "c5_5"],
    ["c2_6", "game", "orange", "violet", "c2_2", "c6_6"],
    ["c2_7", "sum", "orange", "orange", "B", null],
  ],
  r3: [
    ["c3_0", "sum", "yellow", "yellow", "C", null],
    ["c3_1", "game", "red", "red", "c1_1", "c1_1"],
    ["c3_2", "game", "orange", "orange", "c2_2", "c2_2"],
    ["c3_3", "inner", "yellow", "yellow", "C", null],
    ["c3_4", "game", "yellow", "green", "c3_3", "c4_4"],
    ["c3_5", "game", "yellow", "blue", "c3_3", "c5_5"],
    ["c3_6", "game", "yellow", "violet", "c3_3", "c6_6"],
    ["c3_7", "sum", "yellow", "yellow", "C", null],
  ],
  r4: [
    ["c4_0", "sum", "green", "green", "D", null],
    ["c4_1", "game", "green", "red", "c4_4", "c1_1"],
    ["c4_2", "game", "green", "orange", "c4_4", "c2_2"],
    ["c4_3", "game", "green", "yellow", "c4_4", "c3_3"],
    ["c4_4", "inner", "green", "green", "D", null],
    ["c4_5", "game", "blue", "blue", "c5_5", "c5_5"],
    ["c4_6", "game", "violet", "violet", "c6_6", "c6_6"],
    ["c4_7", "sum", "green", "green", "D", null],
  ],
  r5: [
    ["c5_0", "sum", "blue", "blue", "E", null],
    ["c5_1", "game", "blue", "red", "c5_5", "c1_1"],
    ["c5_2", "game", "blue", "orange", "c5_5", "c2_2"],
    ["c5_3", "game", "blue", "yellow", "c5_5", "c3_3"],
    ["c5_4", "game", "blue", "blue", "c5_5", "c5_5"],
    ["c5_5", "inner", "blue", "blue", "E", null],
    ["c5_6", "game", "violet", "violet", "c6_6", "c6_6"],
    ["c5_7", "nothing", null, null, null, null],
  ],
  r6: [
    ["c6_0", "sum", "violet", "violet", "F", null],
    ["c6_1", "game", "violet", "red", "c6_6", "c1_1"],
    ["c6_2", "game", "violet", "orange", "c6_6", "c2_2"],
    ["c6_3", "game", "violet", "yellow", "c6_6", "c3_3"],
    ["c6_4", "game", "violet", "violet", "c6_6", "c6_6"],
    ["c6_5", "game", "violet", "violet", "c6_6", "c6_6"],
    ["c6_6", "inner", "violet", "violet", "F", null],
    ["c6_7", "nothing", null, null, null, null],
  ],
  r7: [
    ["c7_0", "nothing", null, null, null, null],
    ["c7_1", "sum", "red", "red", "A", null],
    ["c7_2", "sum", "orange", "orange", "B", null],
    ["c7_3", "sum", "yellow", "yellow", "C", null],
    ["c7_4", "sum", "green", "green", "D", null],
    ["c7_5", "nothing", null, null, null, null],
    ["c7_6", "nothing", null, null, null, null],
    ["c7_7", "nothing", null, null, null, null],
  ],
};

const boardSegments = {
  left: {
    inner: [
      "c2_1",
      "c3_1",
      "c3_2",
      "c4_1",
      "c4_2",
      "c4_3",
      "c5_1",
      "c5_2",
      "c5_3",
      "c5_4",
    ],
    outer: ["c3_0", "c4_0", "c5_0", "c6_1", "c6_2", "c6_3"],
  },
  right: {
    inner: [
      "c1_2",
      "c1_3",
      "c1_4",
      "c1_5",
      "c2_3",
      "c2_4",
      "c2_5",
      "c3_4",
      "c3_5",
      "c4_5",
    ],
    outer: ["c0_3", "c0_4", "c0_5", "c1_6", "c2_6", "c3_6"],
  },
};

const headerInfo = [
  ["S", "red"],
  ["U", "orange"],
  ["M", "yellow"],
  ["M", "green"],
  ["I", "blue"],
  ["E", "violet"],
];

const fixedCells = [
  ["c1_3", "c3_5", "c5_3", "c3_1", "c1_2", "c2_1", "c4_5", "c5_4"],
  ["c1_2", "c2_1", "c4_5", "c5_4", "c1_5", "c5_1", "c2_4", "c4_2"],
  ["c4_1", "c5_1", "c5_4", "c1_2", "c1_5", "c2_5"],
  ["c1_5", "c5_1", "c2_4", "c4_2"],
  ["c1_2", "c2_1", "c5_4", "c4_5"],
  ["c1_5", "c5_1"],
];

const gameValsDefault = {
  c1_1: null,
  c1_2: null,
  c1_3: null,
  c1_4: null,
  c1_5: null,
  c2_1: null,
  c2_2: null,
  c2_3: null,
  c2_4: null,
  c2_5: null,
  c3_1: null,
  c3_2: null,
  c3_3: null,
  c3_4: null,
  c3_5: null,
  c4_1: null,
  c4_2: null,
  c4_3: null,
  c4_4: null,
  c4_5: null,
  c5_1: null,
  c5_2: null,
  c5_3: null,
  c5_4: null,
  c5_5: null,
};

const btmValsDefault = {
  b0: null,
  b1: null,
  b2: null,
  b3: null,
  b4: null,
  b5: null,
  b6: null,
  b7: null,
  b8: null,
  b9: null,
  b10: null,
  b11: null,
  b12: null,
  b13: null,
  b14: null,
  b15: null,
  b16: null,
  b17: null,
  b18: null,
  b19: null,
};

export {
  gridInfo,
  headerInfo,
  fixedCells,
  gameValsDefault,
  btmValsDefault,
  boardSegments,
};
