import React, { useContext } from "react";

import GameCell from "./GameCell";
import Menu from "./Menu";
import IntroAnimation from "./IntroAnimation";

import { GameContext } from "./Game";
import { gridInfo } from "../Support/Objects";

export default function GameGrid() {
  ////    ////    inits       ////    ////
  const consumeCtxt = useContext(GameContext);
  ////    ////    states      ////    ////
  ////    ////    functions       ////    ////
  ////    ////    useEffects      ////    ////
  ////    ////    styles      ////    ////
  const styles = {
    parentContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      width: consumeCtxt.width * 0.9,
      height: consumeCtxt.width * 0.9,
      margin: "5px",
      border: "2px solid black",
      backgroundColor: consumeCtxt.colourScheme.background,
    },
    rowContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-evenly",
      height: "11%",
    },
  };
  ////    ////    component       ////    ////
  return (
    <div style={styles.parentContainer}>
      {consumeCtxt.phase === "game-active" ? (
        <>
          <div style={styles.rowContainer}>
            {gridInfo.r0.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r1.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r2.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r3.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r4.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r5.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r6.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
          <div style={styles.rowContainer}>
            {gridInfo.r7.map((el, i) => {
              return (
                <GameCell
                  letter={el[4]}
                  key={el[0]}
                  id={el[0]}
                  style={el[1]}
                  color={el[2]}
                />
              );
            })}
          </div>
        </>
      ) : consumeCtxt.phase === "pregame" ? (
        <Menu />
      ) : consumeCtxt.phase === "animating-intro" ? (
        <IntroAnimation />
      ) : null}
    </div>
  );
}
