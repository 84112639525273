import React, { useContext, useState } from "react";

import { GameContext } from "./Game";

export default function Menu() {
  ////    ////    inits   ////    ////
  const consumeCtxt = useContext(GameContext);

  ////    ////    states    ////    ////
  const [diff, setDiff] = useState(0);

  ////    ////    functions   ////    ////

  ////    ////    styles    ////    ////
  const styles = {
    menuTxt: {
      color: "white",
    },
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        alignItems: "center",
        justifyContent: "space-evenly",
      }}
    >
      <img src="./logo-edges-masked.png" height={consumeCtxt.width / 3} />
      {consumeCtxt.width > 400 ? (
        <h2
          style={{
            color: "skyblue",
            textAlign: "center",
            padding: 5,
            fontFamily: "fantasy",
          }}
        >
          Play Summie right here in the browser!
        </h2>
      ) : (
        <h4
          style={{
            color: "skyblue",
            textAlign: "center",
            padding: 5,
            fontFamily: "fantasy",
          }}
        >
          Play Summie right here in the browser!
        </h4>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div>
          <label for="diff-select" style={{ color: "lightgrey" }}>
            Select difficulty:
          </label>
          <br></br>
          <select
            id="diff-select"
            value={diff}
            onChange={(e) => {
              setDiff(e.target.value);
            }}
          >
            <option value={0}>Easy</option>
            <option value={1}>Not So Easy</option>
            <option value={2}>Slightly Stressful</option>
            <option value={3}>Kinda Hard</option>
            <option value={4}>Pretty Damn Tricky</option>
            <option value={5}>Break My Brain!</option>
          </select>
        </div>
        <button
          style={{
            backgroundColor: "lightgreen",
            height: "100%",
            border: "2px solid black",
          }}
          onClick={() => {
            consumeCtxt.handleStartGame(diff);
          }}
        >
          PLAY NOW!
        </button>
      </div>
    </div>
  );
}
