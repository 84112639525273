import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

export default function About() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, transition: { duration: 0.5 } }}
    >
      <h1>About</h1>
      <h4>Coming soon ...</h4>
    </motion.div>
  );
}
