import React from "react";
import "animate.css";

export default function IntroAnimation() {
  return (
    <h1
      className="animate__animated animate__bounceInDown"
      style={{ margin: "0 auto", color: "skyblue", fontFamily: "fantasy" }}
    >
      Let's Play!
    </h1>
  );
}
