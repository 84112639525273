import "./App.css";
import React, { useEffect } from "react";
import NavBar from "./Components/NavBar";
import AnimatedRoutes from "./Screens/AnimatedRoutes";

import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Router>
        <NavBar />
        <AnimatedRoutes />
      </Router>
    </div>
  );
}

export default App;
